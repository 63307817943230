<template>
  <div class="My360ApplicationDetails">
    <!-- 360/KPI添加考核申请 -->
    <van-nav-bar :title="$t('jxStatisticalDataDetails.sddTxt1')"
                 left-text
                 left-arrow
                 @click-left="onClickLeft"
                 :fixed="true" />
    <div class="content">
      <van-form>
        <van-field :value="form.parentTitle"
                   :label="$t('jxStatisticalDataDetails.sddTxt2')"
                   readonly
                   input-align="right" />
        <van-field :value="userInfo.empcode"
                   :label="$t('jxStatisticalDataDetails.sddTxt3')"
                   readonly
                   input-align="right"
                   v-show="form.is360 == 'false'" />
        <van-field :value="userInfo.empname"
                   :label="$t('jxStatisticalDataDetails.sddTxt4')"
                   readonly
                   input-align="right"
                   v-show="form.is360 == 'false'" />
        <van-field :value="empInfo.deptname"
                   :label="$t('jxStatisticalDataDetails.sddTxt5')"
                   readonly
                   input-align="right"
                   v-show="form.is360 == 'false'" />
        <van-field :value="empInfo.staname"
                   :label="$t('jxStatisticalDataDetails.sddTxt6')"
                   readonly
                   input-align="right"
                   v-show="form.is360 == 'false'" />
        <!-- 直接评价人工号 -->
        <van-field label-class="vantitcls"
                   :label="$t('jxStatisticalDataDetails.sddTxt7')"
                   readonly
                   input-align="right"
                   :disabled="ischeck == 'true'"
                   :value="form.itemname"
                   @click="showEmpTreePop('项目名称', '', ischeck)" />
        <van-cell class="sqnum_cell"
                  :title="$t('jxStatisticalDataDetails.sddTxt8')"
                  title-class="vantitcls">
          <template #default>
            <input :disabled="ischeck == 'true'"
                   style="
                width: 50px;
                text-align: center;
                border: 0;
                border-bottom: 1px solid #ccc;
              "
                   type="text"
                   v-model="form.itemval" />
          </template>
        </van-cell>
        <van-cell class="desc_cell"
                  :value="$t('jxStatisticalDataDetails.sddTxt9')" />
        <van-field class="desc_field"
                   v-model="form.remark"
                   rows="8"
                   autosize
                   :disabled="ischeck == 'true'"
                   type="textarea" />
      </van-form>
      <!-- 项目附件 -->
      <div style="padding: 10px; border-top: 1px solid;">
        <div style="text-align: left;margin-bottom: 10px; color: #969799; font-size: 16px;">附件（最多15张）</div>
        <div style="display:flex;flex-wrap: wrap;justify-content: flex-start;">
          <div v-for="(it,index) in tjAttFileList"
               :key="index"
               style="margin:0 8px 8px 0">
            <van-badge>
              <van-image v-if="it.fileext.indexOf('pdf')==-1"
                         width="80"
                         height="80"
                         :src="it.url"
                         @click="imagePreview(tjAttFileList)" />
              <van-image v-else
                         width="80"
                         height="80"
                         :src="require('@assets/img/xmind.png')"
                         @click="showPdf(it)" />
              <template #content>
                <van-icon name="cross"
                          class="badge-icon"
                          @click="deleteAttFile(it,'norm','tj')" />
              </template>
            </van-badge>
          </div>
        </div>
        <van-uploader accept="image/*,.pdf"
                      preview-size="80px"
                      v-model="tjFileList"
                      :max-count="15- tjAttFileList.length"
                      :after-read="afterRead" />
      </div>
    </div>
    <div class="footer">
      <van-button type="info"
                  v-if="ischeck == 'false'"
                  size="small"
                  @click="save">{{ $t("jxStatisticalDataDetails.sddTxt10") }}</van-button>
    </div>
    <PopTree ref="popTreeNode"
             :whichTree="'othertree'"
             :multiseltree="false"
             @treeSelectValEvent="treeSelectValEvent"></PopTree>
    <!-- pdf内置播放器 -->
    <pdfView :pdf="pdf"></pdfView>
  </div>
</template>

<script>
import { Toast, Dialog, ImagePreview } from "vant";
import PopTree from "@components/PopTree/index.vue";
import pdfView from "@components/pdfView/index.vue";
import {
  getCommTreeData,
  savePubCiDetail,
  savePerCiDetail,
  getPubCiDetail,
  getPerCiDetail,
  upLoadBase64FileFixName
} from "@api/wxjx.js";
import { getModuleAttFileList } from "@api/wxzp.js";
import { createGguid } from "@/utils";
import { getEmpInfo, deleteFile } from "@api/wx";
export default {
  components: {
    PopTree,
    pdfView
  },
  data () {
    let mautoid = this.$route.query.mautoid;
    let detaId = this.$route.query.detaId;
    let length = this.$route.query.length;
    let ischeck = this.$route.query.ischeck;
    const userInfo = localStorage.userInfo
      ? JSON.parse(localStorage.userInfo)
      : {};
    return {
      userInfo,
      mautoid,
      detaId,
      length,
      ischeck,
      form: {
        parentTitle: this.$route.query.parentTitle,
        is360: this.$route.query.is360,
        itemval: "",
        itemname: this.$t("jxStatisticalDataDetails.sddTxt11"),
        remark: "",
        itemid: 0,
      },
      showPicker: false,
      typeColumns: [],
      showTreePop: false, // 树弹窗显示
      empInfo: {
        deptname: "",
        staname: "",
      },
      ajaxModule: "",
      ajaxEditstate: 2,
      ajaxTreeName: "",
      tjFileList: [],
      tjAttFileList: [],
      pdf: '',
    };
  },
  computed: {
    showWeight () {
      if (this.form.is360 == false || this.form.is360 == "false")
      {
        return true;
      } else
      {
        return false;
      }
    },
    moduleNum () {
      if (this.form.is360 == false || this.form.is360 == "false")
      {
        return 514
      } else
      {
        return 506
      }
    }
  },
  created () {
    getEmpInfo({
      empcode: this.userInfo.empcode,
    }).then((res) => {
      this.empInfo = res.data[0];
    });
    if (this.form.is360 == true || this.form.is360 == "true")
    {
      this.getMy360InDetail();
    } else
    {
      this.getMyKpiInDetail();
    }
    this.getModuleAttFileListForPlan()
  },
  watch: {
    showTreePop (n) {
      if (n == false)
      {
        this.parentTreeList = []; // 上级树集合
        this.childsTreeList = []; // 子节点
        this.clickParentTree = []; // 点击过的父节点
        this.otherTreeList = []; // 其他树节点
        this.searchTreeVal = "";
      }
    },
    tjFileList (n) {
      if (n && n.length > 0)
      {
        n.forEach(el => {
          const guid = createGguid();
          el.fileext = el.file.type.split('/')[1]
          el.filename = this.moduleNum + '_' + guid
          el.filebase64str = el.content.split('base64,')[1]
        });
      }
    }
  },
  methods: {
    // 获取整个方案的附件列表
    getModuleAttFileListForPlan () {
      let moduleno = this.moduleNum
      let mautoid = this.detaId
      let lists = []
      getModuleAttFileList({
        moduleno: moduleno,
        mautoid: mautoid,
        username: this.userInfo.username
      }).then(res => {
        lists = res.module_atlist
        if (lists.length > 0)
        {
          lists.forEach(el => {
            this.tjAttFileList.push(el)
          })
        }
      })
    },
    // 预览PDF
    showPdf (it) {
      this.pdf = it.url + "?time=" + new Date().getTime();
    },
    // 预览图片
    imagePreview (list) {
      let imgs = []
      list.forEach(el => {
        imgs.push(el.url)
      })
      ImagePreview(imgs)
    },
    afterRead (file) {
      // console.log(file, 'file');
    },
    // 上传附件
    upLoadBase64File (fileList, moduleno, autoid) {
      for (const file of fileList)
      {
        let data = {
          filebase64str: file.filebase64str,
          upflag: 2,
          moduleno: moduleno,
          autoid: autoid,
          uploadpath: 'AttachFile',
          filename: file.filename,
          fileext: file.fileext,
          username: this.userInfo.username
        }
        upLoadBase64FileFixName(data).then(res => {

        })
      }
    },
    // 删除附件
    deleteAttFile (file, type, str) {
      console.log(file, 'filefilefile');
      if (file.sfilename)
      {
        Dialog.confirm({
          // title: '标题',
          message:
            '确认删除该附件吗？',
        }).then(() => {
          deleteFile({
            dflag: 2,
            moduleno: file.moduleno,
            autoid: file.mautoid,
            filename: file.sfilename,
            fileext: file.fileext, // .split('.')[1],
            downloadpath: 'AttachFile'
          }).then(res => {
            this.tjAttFileList = this.tjAttFileList.filter(el => { return el.autoid != file.autoid })
            this.$forceUpdate()
          })
        }).catch(() => {
          // on cancel
        });

      }
    },
    getMy360InDetail () {
      getPubCiDetail({ autoid: this.detaId }).then((res) => {
        if (res.iserror == 0)
        {
          let data = res.data[0];
          this.form.itemval = data.itemvalue;
          this.form.itemname = data.itemname;
          this.form.remark = data.remark;
          this.form.itemid = data.calitemid;
        }
      });
    },
    getMyKpiInDetail () {
      getPerCiDetail({ autoid: this.detaId }).then((res) => {
        if (res.iserror == 0)
        {
          let data = res.data[0];
          this.form.itemval = data.itemvalue;
          this.form.itemname = data.itemname;
          this.form.remark = data.remark;
          this.form.itemid = data.calitemid;
        }
      });
    },
    // 打开树
    showEmpTreePop (val, code, isc) {
      if (isc == "true")
      {
        return;
      }
      if (this.form.is360 == false || this.form.is360 == "false")
      {
        this.$refs.popTreeNode.module = "514";
        this.$refs.popTreeNode.treename = "calitemtree:4";
        this.$refs.popTreeNode.editstate = 2;
      } else
      {
        this.$refs.popTreeNode.module = "506";
        this.$refs.popTreeNode.treename = "calitemtree:3";
        this.$refs.popTreeNode.editstate = 2;
      }

      this.$refs.popTreeNode.showEmpTreePop(val, code);
    },
    treeSelectValEvent (data) {
      console.log(data);
      this.form.itemname = data.keyname;
      this.form.itemid = data.autoid;
    },
    onClickLeft () {
      // this.$router.push('/jxMyAssTargetApply')
      this.$router.push({
        path: "/jxStatisticalDataList",
        query: {
          title: this.$route.query.parentTitle,
          is360: this.$route.query.is360,
          mautoid: this.mautoid,
        },
      });
    },
    // 添加按钮
    save () {
      // 没有ID的是新增
      if (this.form.is360 == true || this.form.is360 == "true")
      {
        // 360新增
        // console.log(this.form, "360新增")

        if (
          (this.form.itemval !== 0 || this.form.itemval !== "0") &&
          !this.form.itemval
        )
        {
          Toast(this.$t("jxStatisticalDataDetails.sddTxt12"));
          return;
        }
        if (!this.form.itemid)
        {
          Toast(this.$t("jxStatisticalDataDetails.sddTxt13"));
          return;
        }
        savePubCiDetail({
          username: this.userInfo.username,
          autoid: this.detaId,
          calitemid: this.form.itemid,
          itemvalue: this.form.itemval,
          remark: this.form.remark,
        }).then((res) => {
          if (res.iserror == 0)
          {
            if (res.data[0].info)
            {
              Toast(res.data[0].info);
            } else
            {
              Toast(this.$t("jxStatisticalDataDetails.sddTxt14"));
              this.onClickLeft();
            }
          }
        });
      } else
      {
        if (
          (this.form.itemval !== 0 || this.form.itemval !== "0") &&
          !this.form.itemval
        )
        {
          Toast(this.$t("jxStatisticalDataDetails.sddTxt12"));
          return;
        }
        if (!this.form.itemid)
        {
          Toast(this.$t("jxStatisticalDataDetails.sddTxt13"));
          return;
        }
        // KPI新增
        // console.log(this.form, "KPI新增");
        savePerCiDetail({
          username: this.userInfo.username,
          autoid: this.detaId,
          calitemid: this.form.itemid,
          itemvalue: this.form.itemval,
          remark: this.form.remark,
        }).then((res) => {
          if (res.iserror == 0)
          {
            if (res.data[0].info)
            {
              Toast(res.data[0].info);
            } else
            {
              Toast(this.$t("jxStatisticalDataDetails.sddTxt14"));
              this.onClickLeft();
            }
          }
        });
      }

      // 如果有附件那就上传
      if (this.tjFileList && this.tjFileList.length > 0)
      {
        this.upLoadBase64File(this.tjFileList, this.moduleNum, this.detaId)
      }
    },
  },
};
</script>

<style lang="less" scoped>
.My360ApplicationDetails {
  height: 100vh;
  overflow: hidden;
  /deep/.vantitcls {
    color: red !important;
  }
  /deep/.vantitcls {
    span {
      color: red !important;
    }
  }
  .van-nav-bar {
    background: #2b8df0;

    /deep/ .van-nav-bar__left {
      .van-icon {
        color: #fff;
      }
    }
    /deep/.van-nav-bar__title {
      color: #fff;
      font-size: 36px;
      font-family: Source Han Sans CN;
    }
  }
  .content {
    overflow: auto;
    margin-top: 1.22667rem;
    height: calc(100% - 192px);
    .van-form {
      /deep/.van-field,
      .van-cell {
        margin: 30px 0;
        .van-cell__title {
          font-size: 32px;
          font-family: Source Han Sans CN;
          font-weight: bold;
          color: #000000;
        }
        .van-cell__value {
          .van-field__body {
            .van-field__control {
              font-size: 32px;
              font-family: Source Han Sans CN;
              font-weight: 500;
              color: #333333;
            }
          }
        }
      }
      .desc_cell {
        margin-bottom: 0;
        .van-cell__value {
          font-size: 32px;
          font-family: Source Han Sans CN;
          font-weight: bold;
          color: #000000;
        }
      }
      .desc_field {
        margin-top: 0;
      }
    }
  }
  .footer {
    position: absolute;
    background-color: #ffffff;
    bottom: 0;
    height: 100px;
    width: 100%;
    display: flex;
    align-items: center;
    /* text-align: center; */
    justify-content: center;
    .van-button {
      width: 60%;
      border-radius: 12px;
      .van-button__text {
        font-size: 36px;
        font-family: Source Han Sans SC;
        font-weight: 500;
      }
    }
  }
}
</style>